.text_header{
    font-size: 16px;
    font-weight: 600;
}

.ant-descriptions-row > td{
    margin-left: 2px;
    padding: 0px 10px;
}
.ant-descriptions-row > th{
    margin-left: 2px;
    padding: 0px 5px;
}

.ant-descriptions-header{
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
}
.ant-spin-nested-loading{
    width: 100% !important;
}